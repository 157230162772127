import React, { useEffect, useState } from "react";
import { motion, AnimatePresence, useIsPresent } from "framer-motion";
import { useNavigate } from "react-router-dom";
import {
  pageTransition,
  overlayAnimation,

} from "../Components/Animations";

import Spline from '@splinetool/react-spline';


    

const Home = () => {
  const [showTitle, setShowTitle] = useState(true);

  const navigate = useNavigate();

  setTimeout(() => {
    setShowTitle(false);
  }, 4000);

  useEffect(() => {
    if (showTitle == false) navigate("/Home");
  }, [showTitle]);

  return (
    <motion.div className="Page" {...pageTransition}>
      <motion.div className="Welcome" {...overlayAnimation}>
        <AnimatePresence>
        {showTitle && <motion.h1 exit={{ opacity: 0 }}>Welcome</motion.h1>}
          
        </AnimatePresence>
      </motion.div>
    </motion.div>
  );
};

export default Home;
