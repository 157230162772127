import { useState } from "react";
import home from "../Assets/Backgrounds/Home.mp4";
import { motion } from "framer-motion";
import {
  pageTransition,
  overlayAnimation,
  pageContentAnimation,
} from "../Components/Animations";
import ListContainer from "../Components/Content/ListContainer";
import { HomeContent } from "../Components/Content/ScreenContent";
import "../Components/Content/contentStyle.css";

const Home = () => {
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  return (
    <>
      <motion.div className="ContentBox" {...pageContentAnimation}>
        <ListContainer items={HomeContent} />
      </motion.div>

      <motion.div className="Page" {...pageTransition}>
        <motion.div className="overlay" {...overlayAnimation}></motion.div>

        <div className="BackgroundVideo">
          <video
            width="100%"
            allowfullscreen="true"
            muted="muted"
            autoplay="autoplay"
            playsinline="playsinline"
            loop="loop"
          >
            <source src={home} type="video/mp4" />
          </video>
        </div>
      </motion.div>
    </>
  );
};

export default Home;
