import React from "react";
import ListCard from "./ListCard";

const ListContainer = ({ items }) => {
  return (
    <>
      {items.map((item) => (
        <ListCard item={item} />
      ))}
    </>
  );
};

export default ListContainer;
