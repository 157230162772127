import contact from "../Assets/Backgrounds/Contact.mp4";
import { motion } from "framer-motion";
import {
  pageTransition,
  overlayAnimation,
  pageContentAnimation,
} from "../Components/Animations";
import ListContainer from "../Components/Content/ListContainer";
import { ContactContent } from "../Components/Content/ScreenContent";

const Contact = () => {
  return (
    <>
      <motion.div className="ContentBox" {...pageContentAnimation}>
        <ListContainer items={ContactContent} />
      </motion.div>

      <motion.div className="Page" {...pageTransition}>
        <motion.div className="overlay" {...overlayAnimation}></motion.div>

        <div className="BackgroundVideo">
          <video
            width="100%"
            allowfullscreen="true"
            muted="muted"
            autoplay="autoplay"
            playsinline="playsinline"
            loop="loop"
          >
            <source src={contact} type="video/mp4" />
          </video>
        </div>
      </motion.div>
    </>
  );
};

export default Contact;
