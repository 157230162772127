import ReactPlayer from "react-player";
import { Document, Page } from "react-pdf";

import Tristan from "../../Assets/Pictures/Tristan.png";
import PhoneIphoneIcon from "@mui/icons-material/PhoneIphone";
import EmailIcon from "@mui/icons-material/Email";
import Tooltip from "@mui/material/Tooltip";

import antd from "../../Assets/Logos/antd.png";
import aws from "../../Assets/Logos/aws.png";
import framer from "../../Assets/Logos/framer.png";
import github from "../../Assets/Logos/github.png";
import graphql from "../../Assets/Logos/graphql.png";
import jira from "../../Assets/Logos/jira.png";
import csharp from "../../Assets/Logos/csharp.png";
import mapbox from "../../Assets/Logos/mapbox.png";
import materialUi from "../../Assets/Logos/materialUi.png";
import react from "../../Assets/Logos/react.png";
import androidStudio from "../../Assets/Logos/androidStudio.png";
import sqlServer from "../../Assets/Logos/sqlServer.png";
import mongoDb from "../../Assets/Logos/mongoDb.png";
import postgres from "../../Assets/Logos/postgres.png";
import azure from "../../Assets/Logos/azure.png";
import blazor from "../../Assets/Logos/blazor.png";
import linux from "../../Assets/Logos/linux.png";
import windows from "../../Assets/Logos/windows.png";
import bureauVeritas from "../../Assets/Logos/bureauVeritas.png";
import interoper8 from "../../Assets/Logos/interoper8.png";
import rust from "../../Assets/Logos/rust.png";
import goLang from "../../Assets/Logos/goLang.png";
import apple from "../../Assets/Logos/apple.png";
import git from "../../Assets/Logos/git.png";
import azureDevops from "../../Assets/Logos/azureDevops.png";
import xUnit from "../../Assets/Logos/xUnit.png";

import expo from "../../Assets/Pictures/clocs/expo.jpg";
import expoPoster from "../../Assets/Pictures/clocs/ClocsPoster.jpg";

import javascript from "../../Assets/Logos/javascript.png";
import python from "../../Assets/Logos/python.png";
import c from "../../Assets/Logos/c.png";
import css3 from "../../Assets/Logos/css3.png";
import html5 from "../../Assets/Logos/html5.png";
import ruby from "../../Assets/Logos/ruby.png";
import kotlin from "../../Assets/Logos/kotlin.png";
import java from "../../Assets/Logos/java.png";
import sql from "../../Assets/Logos/sql.png";

import "./contentStyle.css";
import Typography from "@mui/material/Typography";
import { CssSharp } from "@mui/icons-material";
import { Center } from "@react-three/drei";

export const HomeContent = [
  {
    key: 1,
    content: (
      <>
        <h1>Hi There 👋</h1>
        <p>
          My name is Tristan Sly, I am a full-stack developer with experience
          primarily in .NET and modern JavaScript.
        </p>

        <div style={{ height: "auto", width: "50%", maxWidth: "250px" }}>
          <img src={Tristan} />
        </div>
      </>
    ),
  },
  {
    key: 2,
    content: (
      <>
        <h1>Qualifications</h1>

        <div className="element">
          <h3>Bachelor of Computer Science</h3>
          <p>2020 - 2022</p>
        </div>
        <div className="element">
          <h3>Advanced Diploma of Electronics Engineering</h3>
          <p>2018 - 2019</p>
        </div>
      </>
    ),
  },
  {
    key: 3,
    content: (
      <>
        <h1>Interests</h1>

        <div className="element">
          <h3>Full Stack Web Development</h3>
          <p>
            I love programming and want to know as much as I can. I thrive in
            areas where I'm allowed to dabble in as much of the stack as
            possible, from front-end, back-end, DevOps, and networking. I enjoy
            it all and hope to continue to grow my skills.
          </p>
        </div>

        <div className="element">
          <h3>IOT Programming</h3>
          <p>
            Because of my background in electronics, I have always had an
            interest in low-level applications and I wish to pursue this in the
            future.
          </p>
        </div>
        <div className="element">
          <h3>Design</h3>
          <p>
            I have found myself designing a lot from posters to videos; this has
            become really enjoyable for me. I particularly like front-end
            development for the creative freedom to work and create something
            truly impactful.
          </p>
        </div>
      </>
    ),
  },
  {
    key: 4,
    content: (
      <>
        <h1>Hobies</h1>

        <div className="element">
          <h3>Gym</h3>
          <p>
            I've been going to the gym since I was 14, competing in under 18's
            national bench press, winning gold in my weight division.
          </p>
        </div>

        <div className="element">
          <h3>Mixology and Bartending</h3>
          <p>
            I have found a passion in bartending and mixology in the past few
            years, learning a lot from books and YouTube.
          </p>
        </div>
      </>
    ),
  },
];

export const ProjectContent = [
  {
    key: 1,
    content: (
      <>
        <h1>Interoper8</h1>

        <p>
          While working at Edmond, I worked in a team of 2 people on the
          Interoper8 startup project. The goal was to create a low-code, no-code
          solution for hosting APIs and facilitating data transactions through
          an exchange format.
          <br />
          <br />
          I was in charge of most aspects of the codebase, including backend,
          frontend, networking, security, hosting, and DevOps. I would also
          frequently speak to customers about the product from both technical
          and sales perspectives.
          <br />
          <a href="https://interoper8.com.au/">
            <img src={interoper8} alt="Interoper8 Logo"></img>
          </a>
        </p>
      </>
    ),
  },

  {
    key: 2,
    content: (
      <>
        <h1>Migration of legacy .NET apps to AWS</h1>
        <p>
          While working at Bureau Veritas, I was in charge of .NET development
          and internal applications within the Australian branch. I successfully
          migrated internal tools used by hundreds of employees with zero
          downtime, communicating with national and international teams to
          effectively deploy legacy apps with modern CI/CD practices to the
          cloud.
        </p>

        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "Center",
            alignItems: "Center",
            gap: "10px",
          }}
        >
          <img style={{ height: "auto", width: "30%" }} src={aws} />
          <img style={{ height: "auto", width: "30%" }} src={bureauVeritas} />
        </div>
      </>
    ),
  },

  {
    key: 3,
    content: (
      <>
        <h1>Computer Science Final Year Project</h1>

        <p>
          For my final year project, my team and I developed an app designed for
          helping trucking logistics in partnership with&nbsp;
          <a href={"https://clocs-a.org.au/"}>CLOCS-A</a>.
        </p>

        <img src={expo} />
        <img src={expoPoster} />

        <div className="ReactPlayerWrapper">
          <ReactPlayer
            url={"https://www.youtube.com/watch?v=NV0WNxvg8hQ"}
            controls={true}
            height="100%"
            width="100%"
          />
        </div>
      </>
    ),
  },
  {
    key: 4,
    content: (
      <>
        <h1>Drone Project</h1>
        <p>
          When completing my final year of electronics engineering, me and 2
          other team members built an automated drone capable of aerial
          surveillance.
        </p>
        <div className="ReactPlayerWrapper">
          <ReactPlayer
            url={"https://youtube.com/shorts/ALEoc_rDvfQ"}
            controls={true}
            height="100%"
            width="100%"
          />
        </div>
      </>
    ),
  },
  {
    key: 5,
    content: (
      <>
        <h1>Climate Change API</h1>
        <p>
          After watching some videos, I got inspired to build my own API to
          further my knowledge of how they work and how they're built. I
          followed a tutorial and came up with a very simple API which scrapes
          multiple news sources for content related to climate change
          information.
          <br />
          <br />
          For more info, check the link below:
          <br />
          <br />
          <a
            href={
              "https://rapidapi.com/slytristan1/api/climate-change-news120/"
            }
          >
            Climate Change API
          </a>
        </p>
      </>
    ),
  },
];

export const SkillsContent = [
  {
    key: 1,
    content: (
      <>
        <h1>Languages</h1>
        <div className="ListItemContainer">
          <Tooltip title="C# / .Net Core">
            <img src={csharp} />
          </Tooltip>
          <Tooltip title="JavaScript 5">
            <img src={javascript} />
          </Tooltip>
          <Tooltip title="CSS 3">
            <img src={css3} />
          </Tooltip>
          <Tooltip title="HTML 5">
            <img src={html5} />
          </Tooltip>

          <Tooltip title="Python">
            <img src={python} />
          </Tooltip>

          <Tooltip title="C++">
            <img src={c} />
          </Tooltip>
          <Tooltip title="Ruby on rails">
            <img src={ruby} />
          </Tooltip>

          <Tooltip title="Kotlin">
            <img src={kotlin} />
          </Tooltip>
          <Tooltip title="Java EE and SE">
            <img src={java} />
          </Tooltip>
          <Tooltip title="GoLang">
            <img src={goLang} />
          </Tooltip>
          <Tooltip title="Rust">
            <img src={rust} />
          </Tooltip>
        </div>
      </>
    ),
  },
  {
    key: 2,
    content: (
      <>
        <h1>Databases</h1>
        <div className="ListItemContainer">
          <Tooltip title="Sql Server">
            <img src={sqlServer} />
          </Tooltip>
          <Tooltip title="Mongo Db">
            <img src={mongoDb} />
          </Tooltip>
          <Tooltip title="PostgreSQL">
            <img src={postgres} />
          </Tooltip>
          <Tooltip title="MySQL">
            <img src={sql} />
          </Tooltip>
        </div>
      </>
    ),
  },
  {
    key: 3,
    content: (
      <>
        <h1>Frameworks/Librarys</h1>
        <div className="ListItemContainer">
          <Tooltip title="React JS and Native">
            <img src={react} />
          </Tooltip>
          <Tooltip title="Blazor">
            <img src={blazor} />
          </Tooltip>
          <Tooltip title="xUnit">
            <img src={xUnit} />
          </Tooltip>
          <Tooltip title="Ant Design">
            <img src={antd} />
          </Tooltip>
          <Tooltip title="Framer Motion">
            <img src={framer} />
          </Tooltip>
          <Tooltip title="Mapbox">
            <img src={mapbox} />
          </Tooltip>
          <Tooltip title="Material UI">
            <img src={materialUi} />
          </Tooltip>
        </div>
      </>
    ),
  },
  {
    key: 4,
    content: (
      <>
        <h1>Development Tools</h1>
        <div className="ListItemContainer">
          <Tooltip title="Amazon Web Services">
            <img src={aws} />
          </Tooltip>
          <Tooltip title="Microsoft Azure">
            <img src={azure} />
          </Tooltip>
          <Tooltip title="Azure Devops / TFS">
            <img src={azureDevops} />
          </Tooltip>

          <Tooltip title="Jira">
            <img src={jira} />
          </Tooltip>

          <Tooltip title="Github / Pages">
            <img src={github} />
          </Tooltip>
          <Tooltip title="Git">
            <img src={git} />
          </Tooltip>
          <Tooltip title="GraphQL">
            <img src={graphql} />
          </Tooltip>
          <Tooltip title="Android Studio">
            <img src={androidStudio} />
          </Tooltip>
        </div>
      </>
    ),
  },
  {
    key: 5,
    content: (
      <>
        <h1>Operating Systems</h1>
        <div className="ListItemContainer">
          <Tooltip title="Linux">
            <img src={linux} />
          </Tooltip>
          <Tooltip title="Microsoft Windows">
            <img src={windows} />
          </Tooltip>
          <Tooltip title="mac OS">
            <img src={apple} />
          </Tooltip>
        </div>
      </>
    ),
  },
];

export const ContactContent = [
  {
    key: 1,
    content: (
      <>
        <h2>My Contact Information</h2>

        <div style={{ height: "auto", width: "50%", maxWidth: "250px" }}>
          <img src={Tristan} />
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "20px",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div>
            <EmailIcon color="primary" />
            <div className="element">
              <h3>Email</h3>
              <a href="mailto:sly.tristan1@gmail.com?subject=Opportunity&body=Hi Tristan, ">
                <p> Sly.tristan1@gmail.com</p>
              </a>
            </div>
          </div>

          <div>
            <PhoneIphoneIcon color="primary" />
            <div className="element">
              <h3>Phone</h3>
              <p>0401059857</p>
            </div>
          </div>
        </div>
      </>
    ),
  },
];
