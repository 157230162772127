import { useScroll } from "framer-motion";

const Animations=()=>{
  const { scrollYProgress } = useScroll();
}

export const pageTransition = {
  initial: { opacity: 0 },
  animate: { opacity: 1 },
  transition: { duration: 1},
  exit: { opacity: 0, transition: { duration: 0.5 } },
};

export const overlayAnimation = {
  initial: { opacity: 0 },
  animate: { opacity: 1 },
  transition: { delay: 1, duration: 1 },
  exit: { opacity: 0, transition: { duration: 0.5 } },
};

export const pageContentAnimation = {
  initial: { y: -2000 },
  animate: { y: 0 },
  transition: {
    delay: 1.5,
    duration: 3,
    type: "spring",
    bounce: 0.25,
  },
  exit: { opacity: 0, transition: { duration: 0.5 } },
};

export const listItemAnimation ={
  initial: { opacity: 0},
  whileInView: {opacity:1},
  transition: {

    duration: 1,
  },
}

export const navBarAnimation ={
    initial:{ y: "-200vh" },
    animate:{ y: "0vh" },
    transition:{ type: "spring", stiffness: 30, delay: 6},
}

export const navTitleAnimation ={
    initial: { opacity: 0 },
    animate: { opacity: 1 },
    transition: { delay: 1, duration: 1 },
    exit: { opacity: 0, transition: { duration: 0.5 } },
}
