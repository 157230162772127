import AnimatedRoutes from "./Components/AnimatedRoutes";
import NavBar from "./Components/NavBar/NavBar";

function App() {
  return (
    <div className="App">
      <NavBar />
      <AnimatedRoutes />
    </div>
  );
}

export default App;
