import React from "react";
import { motion } from "framer-motion";
import { useNavigate } from "react-router-dom";
import {listItemAnimation} from "../Animations"



const ListCard = ({ item }) => {

  return (
    <>
      <motion.div className="ContentListElement" {...listItemAnimation}>{item.content}</motion.div>
    </>
  );
};

export default ListCard;
