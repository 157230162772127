import React, { useEffect, useState, useRef } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { navBarAnimation, overlayAnimation } from "../Animations";
import { useNavigate, useLocation } from "react-router-dom";
import { Button, ButtonGroup } from "@mui/material";
import "./navigation.css";

const menuItems = [
  { key: "/Home", title: "Home" },
  { key: "/skills", title: "Skills" },
  { key: "/projects", title: "Projects" },
  { key: "/contact", title: "Contact" },
];

const NavBar = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const [buttonGroup, setButtonGroup] = useState([]);

  const pageTitle = useRef();

  const page = useRef(location.pathname);
  const [titleVisibile, setTitleVisibile] = useState(true);

  useEffect(() => {
    createButtonArray();
  }, []);

  useEffect(() => {
    if (titleVisibile == true) return;

    setTimeout(() => {
      setTitleVisibile(true);
    }, 1000);
  }, [titleVisibile]);

  //When a Nav item is clicked
  const onButtonClick = (item) => {
    //avoids reloading same page
    if (item.key == page.current) return;

    //holds current page state
    page.current = item.key;

    //allows fade in of page title
    setTitleVisibile(false);

    //navigate to new page
    navigate(item.key);
  };

  //Creates the nav bar based on the menuItems array
  const createButtonArray = () => {
    const buttonArray = [];
    menuItems.map((item) => {
      buttonArray.push(
        <Button style={{ size: "" }} onClick={() => onButtonClick(item)}>
          {item.icon}
          &nbsp;&nbsp;
          {item.title}
        </Button>
      );
    });
    setButtonGroup(buttonArray);
  };

  //handles changing page title
  const setPageTitle = () => {
    if (location.pathname == "/") return;
    const menunobj = menuItems.find((item) => item.key === location.pathname);
    pageTitle.current = menunobj.title;
  };

  setPageTitle();
  return (
    <>
      <div style={{ width: "100%" }}>
        <motion.div className="NavBar" {...navBarAnimation}>
          <div style={{ width: "100%" }}>
            <ButtonGroup
              variant="text"
              aria-label="outlined button group"
              color="primary"
              fullWidth="true"
            >
              {buttonGroup}
            </ButtonGroup>
          </div>
        </motion.div>
      </div>
      <AnimatePresence>
        {titleVisibile && (
          <motion.div {...overlayAnimation}>
            <h1
              style={{ padding: "20px", color: "white", textAlign: "center" }}
            >
              {pageTitle.current}
            </h1>
          </motion.div>
        )}
      </AnimatePresence>
    </>
  );
};

export default NavBar;
