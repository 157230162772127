import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import {
  createTheme,
  ThemeProvider,
  responsiveFontSizes,
} from "@mui/material/styles";
const root = ReactDOM.createRoot(document.getElementById("root"));

let theme = createTheme({
  palette: {
    primary: {
      main: "#3D5467",
    },
  },
});
theme = responsiveFontSizes(theme);

root.render(
  <BrowserRouter>
    <ThemeProvider theme={theme}>
      <App />
    </ThemeProvider>
  </BrowserRouter>
);
